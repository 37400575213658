import React from "react";

import AnimatedCounter from "./AnimatedCounter";
import { Link, useLocation } from "react-router-dom";
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactUsForm from "./ContactUsForm";

const About = () => {
  const location = useLocation();

  // const carouselOptions = {
  //   loop: true,
  //   margin: 10,
  //   nav: true,
  //   autoplay: true,
  //   autoplayTimeout: 3000,
  //   responsive: {
  //     0: { items: 1 },
  //     600: { items: 2 },
  //     1000: { items: 3 },
  //   },
  // };

  return (
    <>
      <div className="container-fluid page-header page-headerAbout py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link
                  to="/"
                  style={{
                    color: location.pathname === "/" ? "#98BC62" : "inherit",
                    fontWeight: location.pathname === "/" ? "bold" : "normal",
                  }}
                >
                  <strong>Home</strong>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to="/about"
                  style={{
                    color:
                      location.pathname === "/about" ? "#98BC62" : "inherit",
                    fontWeight:
                      location.pathname === "/about" ? "bold" : "normal",
                  }}
                >
                  <strong>About Us</strong>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="whoWeAreSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-left">
              <h2 className="main-heading">Our Achievements</h2>
            </div>
            <div className="col-lg-12 text-left">
              <h3 className="sub-heading">An Eye On The Numbers</h3>
            </div>
          </div>
        </div>
        <section
          className="valuableSection"
          style={{ backgroundColor: "black" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="cardAbout" style={{ marginBottom: "10px" }}>
                  <div
                    className="image-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa-regular fa-circle-check fa-3x"
                      style={{
                        color: "#98BC62",
                        transition: "color 0.3s ease",
                      }}
                    ></i>
                  </div>
                  <AnimatedCounter targetNumber={9603} duration={2000} />{" "}
                  {/* Animated number */}
                  <p className="cardAbout-description">Projects Completed</p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="cardAbout" style={{ marginBottom: "10px" }}>
                  <div
                    className="image-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa-solid fa-clipboard-list fa-3x"
                      style={{
                        color: "#98BC62",
                        transition: "color 0.3s ease",
                      }}
                    ></i>
                  </div>
                  <AnimatedCounter targetNumber={530} duration={2000} />{" "}
                  {/* Animated number */}
                  <p className="cardAbout-description">Active Clients</p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="cardAbout " style={{ marginBottom: "10px" }}>
                  <div
                    className="image-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa-solid fa-people-group fa-3x"
                      style={{
                        color: "#98BC62",
                        transition: "color 0.3s ease",
                      }}
                    ></i>
                  </div>
                  <AnimatedCounter targetNumber={636} duration={2000} />{" "}
                  {/* Animated number */}
                  <p className="cardAbout-description">Team Members</p>
                </div>
              </div>

              <div className="col-md-3">
                <div className=" cardAbout">
                  <div
                    className="image-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa-regular fa-thumbs-up fa-3x"
                      style={{
                        color: "#98BC62",
                        transition: "color 0.3s ease",
                      }}
                    ></i>
                  </div>
                  <AnimatedCounter targetNumber={1105} duration={2000} />{" "}
                  {/* Animated number */}
                  <p className="cardAbout-description">Satisfied Clients</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="whoWeAreSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-left">
              <h2 className="main-heading">About Us.</h2>
            </div>
            <div className="col-lg-12 text-left">
              <h3 className="sub-heading">
                We Are Your Global Partners For Digital Success
              </h3>
            </div>
            <div
              className="col-lg-12"
              style={{ textAlign: "justify", marginBottom: "20px" }}
            >
              <p className="description">
                <strong>Our Expertise:</strong> Our team is a blend of diverse
                talents and expertise, coming together to craft digital
                solutions that transcend expectations. From web design and
                development to digital marketing and beyond, our specialists
                leverage their skills to engineer results-driven strategies that
                elevate our clients above the competition.
              </p>
              <p className="description">
                <strong>Client-Centric Approach:</strong> At the heart of our
                success lies a client-centric approach. We believe that our
                clients’ goals are our goals. By meticulously understanding
                their unique needs and challenges, we tailor bespoke solutions
                that not only meet but exceed expectations. Our client
                relationships are built on trust, transparency, and a genuine
                commitment to their success.
              </p>
              <p className="description">
                <strong>Innovation as a Culture:</strong> Innovation as a
                Culture: Innovation is woven into the fabric of Vital Tech Solutions.
                We thrive on pushing boundaries, embracing change, and staying
                at the forefront of industry trends. Our passion for innovation
                fuels our ambition to create groundbreaking solutions that drive
                tangible, measurable results.
              </p>
              <p className="description">
                <strong>Integrity and Transparency: </strong> Integrity and
                transparency are the cornerstones of our operations. We conduct
                our business with unwavering honesty, ensuring that our clients
                are well-informed every step of the way. Trust is not just
                earned; it’s embedded in our DNA.
              </p>
            </div>
          </div>
        </div>
        {/* </section> */}
        {/* <section className="additionalInfoSection"> */}
        <div className="container additionalInfoSection">
          <div className="row">
            <div className="col-md-6">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Experts with extensive marketing knowledge
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body" style={{ fontSize: "14px" }}>
                      From captivating web design to strategic marketing, and
                      exceptional app and game development services, we are your
                      ultimate destination for comprehensive solutions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Service that are on par with industry standards
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body" style={{ fontSize: "14px" }}>
                      With us, you can expect excellence that aligns with the
                      latest trends and practices, ensuring your success in the
                      digital landscape.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Delivery on agreed time frames
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body" style={{ fontSize: "14px" }}>
                      We prioritize delivery on agreed time frames. You can
                      trust us to meet deadlines, ensuring your projects are
                      completed efficiently and on schedule, every time.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6" style={{ marginTop: "20px", }}>
              <div className="additional-content">
                {/* <h4>Additional Information</h4> */}
                <p>
                  Vital Tech Solutions is not just another digital agency; we
                  are the forward-thinking pioneers shaping the future of online
                  success. Our journey began with a bold vision to transform the
                  digital landscape into a dynamic space where innovation
                  thrives, businesses flourish, and creativity leads the way.
                  Established by a team of seasoned industry veterans, our
                  mission is driven by a commitment to excellence and an
                  unwavering passion for pushing the boundaries of digital
                  innovation. With years of experience and a deep understanding
                  of emerging technologies, we strive to deliver solutions that
                  not only meet but exceed our clients' expectations, ensuring
                  they stay ahead in a rapidly evolving digital world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whoWeAreSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="main-heading-2">Together, We See the Future</h2>
            </div>

            <div
              className="col-lg-6"
              style={{ textAlign: "center", marginBottom: "20px", alignContent: "center" }}
            >
              <p className="description">
                <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span>As the CEO and Director, I am honored to lead a team dedicated to delivering innovative and reliable IT solutions tailored to meet the ever-evolving needs of our clients.


              </p>
              <p className="description">
                Our mission is simple yet profound: to empower businesses with cutting-edge technology, ensuring their success and growth in a rapidly changing digital landscape.
                At the heart of our operations lies an unwavering commitment to customer satisfaction and building long-term partnerships based on trust and mutual respect.<span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span></p>

              <div style={{ justifyItems: "center" }}>
                <h3 style={{
                  height: "3px", // Adjust thickness
                  backgroundColor: "#98BC62", // Adjust color
                  width: "25%", // Adjust width
                  margin: "10px 0",
                  textAlign: "center", // Add spacing if needed
                }}></h3>
              </div>
              <div>
                <h3 style={{ textAlign: "center", marginTop: "20px", color: "white" }} >
                  Wafa Ayesha                </h3>
                <h4 style={{ textAlign: "center", color: "white" }} >
                  CEO & Director
                </h4>
              </div>
            </div>

            <div
              className="col-lg-6"
              style={{ textAlign: "justify", marginBottom: "20px" }}
            >
              <img  width="85%"
              style={{ border: "4px solid #98BC62" }}
                src="assets\img\7 (17).jpg"
                className="img-fluid"
                alt="First slide"
              />
            </div>


          </div>
        </div>
        
        
      </section>


      <section className="whoWeAreSection">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-12 text-center">
              <h2 className="main-heading">CFO & Director's Message.</h2>
            </div> */}

            <div
              className="col-lg-6"
              style={{ textAlign: "justify", marginBottom: "20px" }}
            >
              <img  width="85%"
              style={{ border: "4px solid #98BC62" }}
                src="assets\img\6 (14).jpg"
                className="img-fluid"
                alt="First slide"
              />
            </div>

            <div
              className="col-lg-6"
              style={{ textAlign: "center", marginBottom: "20px", alignContent: "center" }}
            >
              <p className="description">
                <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span> As CFO of Vital Tech Solutions, I envision a future where financial leadership drives sustainable growth, innovation, and global impact. My focus is on empowering teams, optimizing resources, and delivering exceptional value to our customers while aligning financial strategy with purpose-driven goals. <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span></p>

              <div style={{ justifyItems: "center" }}>
                <h3 style={{
                  height: "3px", // Adjust thickness
                  backgroundColor: "#98BC62", // Adjust color
                  width: "60%", // Adjust width
                  margin: "10px 0",
                  textAlign: "center", // Add spacing if needed
                }}></h3>
              </div>
              <div>
                <h3 style={{ textAlign: "center", marginTop: "20px", color: "white" }} >
                  Hamza Ahmed Saeed Arain                </h3>
                <h4 style={{ textAlign: "center", color: "white" }} >
                  CFO & Director
                </h4>
              </div>
            </div>

            


          </div>
        </div>
        
        
      </section>

      <section className="whoWeAreSection">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-12 text-center">
              <h2 className="main-heading">Vice President Message.</h2>
            </div> */}

            <div
              className="col-lg-6"
              style={{ textAlign: "center", marginBottom: "20px", alignContent: "center" }}
            >
              <p className="description">
                <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span> At Vital Tech Solutions, we are committed to driving innovation and helping businesses succeed in the digital world. As Vice President of Sales, I take pride in our growth, learning from a talented team, and focusing on delivering personalized, scalable solutions for our clients. We aim to build long-term relationships, positioning ourselves as trusted advisors and one-stop solutions. Every day, I'm inspired by the impact we make and remain dedicated to evolving and growing alongside our clients. <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span></p>

              <div style={{ justifyItems: "center" }}>
                <h3 style={{
                  height: "3px", // Adjust thickness
                  backgroundColor: "#98BC62", // Adjust color
                  width: "35%", // Adjust width
                  margin: "10px 0",
                  textAlign: "center", // Add spacing if needed
                }}></h3>
              </div>
              <div>
                <h3 style={{ textAlign: "center", marginTop: "20px", color: "white" }} >
                  Winah Shahid               </h3>
                <h4 style={{ textAlign: "center", color: "white" }} >
                  Vice President
                </h4>
              </div>
            </div>

            <div
              className="col-lg-6"
              style={{ textAlign: "justify", marginBottom: "20px" }}
            >
              <img  width="85%"
              style={{ border: "4px solid #98BC62" }}
                src="assets\img\3.jpg"
                className="img-fluid"
                alt="First slide"
              />
            </div>


          </div>
        </div>
        
        
      </section>

      <section className="whoWeAreSection">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-12 text-center">
              <h2 className="main-heading">Brand Manager Message.</h2>
            </div> */}

            <div
              className="col-lg-6"
              style={{ textAlign: "justify", marginBottom: "20px" }}
            >
              <img  width="85%"
              style={{ border: "4px solid #98BC62" }}
                src="assets\img\5.jpg"
                className="img-fluid"
                alt="First slide"
              />
            </div>

            <div
              className="col-lg-6"
              style={{ textAlign: "center", marginBottom: "20px", alignContent: "center" }}
            >
              <p className="description">
                <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span> Vital Tech has been instrumental in my growth by offering a supportive environment that encourages skill development and new challenges. I am grateful for the mentorship and guidance from my colleagues, which has greatly contributed to my personal and professional development. <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span></p>

              <div style={{ justifyItems: "center" }}>
                <h3 style={{
                  height: "3px", // Adjust thickness
                  backgroundColor: "#98BC62", // Adjust color
                  width: "50%", // Adjust width
                  margin: "10px 0",
                  textAlign: "center", // Add spacing if needed
                }}></h3>
              </div>
              <div>
                <h3 style={{ textAlign: "center", marginTop: "20px", color: "white" }} >
                  Neha Khan Ghori                </h3>
                <h4 style={{ textAlign: "center", color: "white" }} >
                  Brand Manager
                </h4>
              </div>
            </div>

            


          </div>
        </div>
        
        
      </section>
      

      <section className="whoWeAreSection">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-12 text-center">
              <h2 className="main-heading">Brand Coordinator Message.</h2>
            </div> */}

            <div
              className="col-lg-6"
              style={{ textAlign: "center", marginBottom: "20px", alignContent: "center" }}
            >
              <p className="description">
                <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span> As the Brand Coordinator at Vital Tech Sol. Pvt Ltd, I am dedicated to driving business growth by identifying and cultivating potential sales opportunities and building strong client relationships. My role focuses on strategically positioning our brand to attract new clients, ensuring that we continue to expand our reach and achieve long-term success. I work closely with our team to develop innovative strategies that align with our business goals, helping to enhance our market presence and foster lasting partnerships. <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span></p>

              <div style={{ justifyItems: "center" }}>
                <h3 style={{
                  height: "3px", // Adjust thickness
                  backgroundColor: "#98BC62", // Adjust color
                  width: "35%", // Adjust width
                  margin: "10px 0",
                  textAlign: "center", // Add spacing if needed
                }}></h3>
              </div>
              <div>
                <h3 style={{ textAlign: "center", marginTop: "20px", color: "white" }} >
                  Zain Ali               </h3>
                <h4 style={{ textAlign: "center", color: "white" }} >
                  Brand Coordinator
                </h4>
              </div>
            </div>

            <div
              className="col-lg-6"
              style={{ textAlign: "justify", marginBottom: "20px" }}
            >
              <img  width="85%"
              style={{ border: "4px solid #98BC62" }}
                src="assets\img\2.jpg"
                className="img-fluid"
                alt="First slide"
              />
            </div>


          </div>
        </div>
        
        
      </section>

      <section className="whoWeAreSection">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-12 text-center">
              <h2 className="main-heading">Head Of Production Message.</h2>
            </div> */}

            <div
              className="col-lg-6"
              style={{ textAlign: "justify", marginBottom: "20px" }}
            >
              <img  width="85%"
              style={{ border: "4px solid #98BC62" }}
                src="assets\img\4.jpg"
                className="img-fluid"
                alt="First slide"
              />
            </div>

            <div
              className="col-lg-6"
              style={{ textAlign: "center", marginBottom: "20px", alignContent: "center" }}
            >
              <p className="description">
                <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span> As Head of Production, my vision is to bring ideas to life with a balance of creativity and precision. I focus on keeping things organized, solving challenges as they come, and making sure the team works together smoothly. It’s all about turning big ideas into results that truly make an impact. <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span></p>

              <div style={{ justifyItems: "center" }}>
                <h3 style={{
                  height: "3px", // Adjust thickness
                  backgroundColor: "#98BC62", // Adjust color
                  width: "50%", // Adjust width
                  margin: "10px 0",
                  textAlign: "center", // Add spacing if needed
                }}></h3>
              </div>
              <div>
                <h3 style={{ textAlign: "center", marginTop: "20px", color: "white" }} >
                  Ahmed Saeed                </h3>
                <h4 style={{ textAlign: "center", color: "white" }} >
                  Head Of Production
                </h4>
              </div>
            </div>

            


          </div>
        </div>
        
        
      </section>


      <section className="whoWeAreSection">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-12 text-center">
              <h2 className="main-heading">Brand Strategist Message.</h2>
            </div> */}

            <div
              className="col-lg-6"
              style={{ textAlign: "center", marginBottom: "20px", alignContent: "center" }}
            >
              <p className="description">
                <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span> As a Brand Strategist, I love helping brands find their voice and connect with people in meaningful ways. My goal is to turn ideas into strategies that not only look great but also deliver real results. It’s all about creating stories and experiences that stick. <span style={{ fontSize: "20px", color: "#98BC62", fontWeight: "bold" }}>"</span></p>

              <div style={{ justifyItems: "center" }}>
                <h3 style={{
                  height: "3px", // Adjust thickness
                  backgroundColor: "#98BC62", // Adjust color
                  width: "35%", // Adjust width
                  margin: "10px 0",
                  textAlign: "center", // Add spacing if needed
                }}></h3>
              </div>
              <div>
                <h3 style={{ textAlign: "center", marginTop: "20px", color: "white" }} >
                  Syed Muhammad Bilal Haider               </h3>
                <h4 style={{ textAlign: "center", color: "white" }} >
                Brand Strategist 
                </h4>
              </div>
            </div>

            <div
              className="col-lg-6"
              style={{ textAlign: "justify", marginBottom: "20px" }}
            >
              <img  width="85%"
              style={{ border: "4px solid #98BC62" }}
                src="assets\img\1 (44).jpg"
                className="img-fluid"
                alt="First slide"
              />
            </div>


          </div>
        </div>
        
        
      </section>
      {/* <div
        className="container-fluid pb-5 mb-10 team"
        style={{ backgroundColor: "#000" }}
      >
        <div className="container pb-5">
          <div
            className="text-center mx-auto pb-5"
            style={{ maxWidth: "600px" }}
          >
            <h1 style={{ color: "#98BC62" }}>Our Team</h1>
          </div>

          <OwlCarousel className="owl-theme" {...carouselOptions}>
            <div className="team-item" style={{ backgroundColor: "#fff" }}>
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="https://media.licdn.com/dms/image/v2/D4E03AQEJyoSjix6SmQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1703108499941?e=1730332800&v=beta&t=ZPdAwRkaerGpa-yst6xlo3tQGkh3c6xlXrVEKs_eqy8"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                      style={{ width: "330px", height: "330px" }}
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4 style={{ color: "#2d5f2c" }}>Bilal Haider</h4>
                    <p className="m-0">Social Media Manager</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="team-item" style={{ backgroundColor: "#fff" }}>
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="../assets/img/ahmedImg.jpg"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                      style={{ height: "330px", width: "200px" }}
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4 style={{ color: "#2d5f2c" }}>Ahmed Saeed</h4>
                    <p className="m-0">Project Manager</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="team-item" style={{ backgroundColor: "white" }}>
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="https://media.licdn.com/dms/image/v2/D4D03AQGnDZJQrACdlg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1684859382044?e=1730332800&v=beta&t=-6Czab8eiPNGQhrPIKyKb7MiWGjUGNiKlTo3FhIBg6g"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                      style={{ height: "330px", width: "330px" }}
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4 style={{ color: "#2d5f2c" }}>Sayed Ahsan Ali</h4>
                    <p className="m-0">Web Developer</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="team-item" style={{ backgroundColor: "#fff" }}>
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="http://192.168.18.35:8002/storage/employees/LG3SCAKRVFVRXhJryvNRVgHV7ZhVRZlV5h2egdgT.jpg"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                      style={{ height: "330px", width: "250px" }}
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4 style={{ color: "#2d5f2c" }}>Aashir Khowaja</h4>
                    <p className="m-0">Developer</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href="/"
                      style={{ backgroundColor: "#2d5f2c" }}
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div> */}
      {/* another team section */}
      {/* <div
        className="container-fluid pb-5 mb-10 team"
        style={{ backgroundColor: "#000" }}
      >
        <div className="container pb-5">
          <div
            className="text-center mx-auto pb-5"
            style={{ maxWidth: "600px" }}
          >
            <h1 style={{ color: "#98BC62" }}>Our Team</h1>
          </div>
          <OwlCarousel
            className="owl-theme"
            {...carouselOptions}
            loop
            margin={10}
            nav
          >
            <div className="item col-sm-12 d-flex justify-content-center align-items-center">
              <div className="team-member">
                <img
                  src="https://media.licdn.com/dms/image/v2/D4D03AQGnDZJQrACdlg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1684859382044?e=1730332800&v=beta&t=-6Czab8eiPNGQhrPIKyKb7MiWGjUGNiKlTo3FhIBg6g"
                  alt="team"
                  className="img-fluid"
                  style={{ height: "500px", objectFit: "cover" }}
                />
                <div className="overlay">
                  <div className="text">
                    <h5 style={{ color: "#fff", fontWeight: "bold" }}>
                      Sayed Ahsan Ali
                    </h5>
                    <p style={{fontSize:"17px"}}>Web Developer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item col-sm-12 d-flex justify-content-center align-items-center">
              <div className="team-member">
                <img
                  src="../assets/img/ahmedImg.jpg"
                  alt="team"
                  className="img-fluid"
                  style={{ height: "500px", objectFit: "cover" }}
                />
                <div className="overlay">
                  <div className="text">
                    <h5 style={{ color: "#fff", fontWeight: "bold" }}>
                      Ahmed Saeed
                    </h5>
                    <p style={{fontSize:"17px"}}>Team Coordinator (Production & IT)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item col-sm-12 d-flex justify-content-center align-items-center">
              <div className="team-member">
                <img
                  src="http://192.168.18.35:8002/storage/employees/LG3SCAKRVFVRXhJryvNRVgHV7ZhVRZlV5h2egdgT.jpg"
                  alt="team"
                  className="img-fluid"
                  style={{ height: "500px", objectFit: "cover" }}
                />
                <div className="overlay">
                  <div className="text">
                    <h5 style={{ color: "#fff", fontWeight: "bold" }}>
                      Aashir Khowaja
                    </h5>
                    <p style={{fontSize:"17px"}}>React Developer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item col-sm-12 d-flex justify-content-center align-items-center">
              <div className="team-member">
                <img
                  src="../assets/img/bilalImg.jpg"
                  alt="team"
                  className="img-fluid"
                  style={{ height: "500px", objectFit: "cover" }}
                />
                <div className="overlay">
                  <div className="text">
                    <h5 style={{ color: "#fff", fontWeight: "bold" }}>
                      Bilal Haider
                    </h5>
                    <p style={{fontSize:"17px"}}>Marketing Manager</p>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div> */}

      {/* calling ContactUsForm */}
      <ContactUsForm />
    </>
  );
};

export default About;
